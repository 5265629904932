
import { Vue, Component } from "vue-property-decorator";
import { ListDataSource } from "@/data/List/ListDataSource";
@Component
export default class Home extends Vue {
  dataSource: any = new ListDataSource({
    config: {
      pageIndex: 1,
      pageSize: 15,
    },
    className: "UserConsentCancellation",
  });

  headers: Array<any> = [
    {
      text: "ID",
      value: "id",
      sortable: true,
      width: 100,
    },
    {
      text: "Дата создания",
      value: "createDate",
      sortable: false,
    },
    {
      text: "ФИО",
      value: "fullName",
      sortable: false,
    },
    {
      text: "Согласие",
      value: "consent",
      sortable: false,
    },

    {
      text: "Статус",
      value: "instanceStateId",
      sortable: false,
    },
  ];

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Заявки для отмены согласия",
        disabled: true,
      },
    ];
  }


}
